<template>
  <section class="policy-created">
    <div
      v-if="!detail.policy_no"
      class="header-title-back flex hand"
      @click="() => $router.back()"
    >
      <i class="el-icon-arrow-left"></i>
      <span>
        Back
      </span>
    </div>
    <div
      v-else
      class="header-title-back title-margin-bottom">
      <span
        class="title-content"
        @click="() => $router.back()">All Policy</span>
      <i
        class="el-icon-arrow-right icon-little"
      ></i>
      <span class="title-litter">#{{detail.policy_no}}</span>
    </div>

    <el-row
      :gutter="20"
      justify="space-around">

      <el-col
        class=""
        :xs="24"
        :sm="24"
        :md="24"
        :lg="14"
        :xl="14">
        <div class="background-white">
          <div class="flex flex-justify ">
            <div class="flex">
              <span
                v-if="!showOtherInsurer"
                class="company-logo">
                <img
                  :src="getOptionData('insureOptions',detail.company_id).logo"
                  alt="company" />
              </span>
              <span v-else>Other Insurer</span>
              <h2
                v-if="detail.product_id==10"
                class="small-font-size">Motor Insurance - {{getOptionName('carClassOptions',detail.class_id)}}</h2>
              <h2
                v-else
                class="small-font-size">{{getOptionName('productTypeOptions',detail.product_id)}}</h2>
            </div>
            <div>
              <v-button
                v-if="detail.policy_status!=10"
                :class="getOptionName('buttonColorClassOption',detail.policy_status)"
                disabled>{{statusData(detail.policy_status)}}</v-button>
              <span
                v-if="detail.is_want_renew"
                class="retweet">
                <font-awesome-icon icon="retweet"></font-awesome-icon>
              </span>
            </div>
          </div>

          <div class="table">
            <div></div>
            <div
              class="tr">
              <span>Insurer</span>
              <span>{{detail.company_name}}
              </span>
            </div>
            <div
              class="tr">
              <span>Insurance Type</span>
              <span>{{getOptionName('productTypeOptions',detail.product_id)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Policyholder</span>
              <span>{{detail.policy_holder}}
              </span>
            </div>
            <div
              class="tr">
              <span>Policy Number</span>
              <span>{{detail.policy_no}}
              </span>
            </div>
            <div
              class="tr">
              <span>Created On</span>
              <span>{{showHKTime(detail.created_at)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Last Updated</span>
              <span>{{showHKTime(detail.last_updated)}}
              </span>
            </div>
          </div>
        </div>
      </el-col>

      <el-col

        :xs="24"
        :sm="24"
        :md="24"
        :lg="14"
        :xl="14">
        <div v-if="draftOrder.length>0">
          <h2 class="draft-title">Draft</h2>
          <div
            v-for="(item,index) in draftOrder"
            :key="'draftOrder_'+index"
            class="background-white"
            @click="handlerEdit(item.order_no,true,detail.product_id)">
            <div class="flex flex-justify">
              <div class="flex">

                <span class="margin-right">{{showHKTime(item.created_at)}}</span>
                <v-button
                  disabled
                  class="show-table-maxscreen"
                  :class="getOptionName('buttonClassOption',item.policy_type)">{{getOptionName('policyTypeOption',item.policy_type)}}</v-button>
              </div>
              <div>
                <!--                <v-button-->
                <!--                  v-if="item.is_want_renew"-->
                <!--                  class="button-blue"-->
                <!--                  @click="createRenew(item.order_no,detail.product_id)">Create Renew Quote</v-button>-->
                <v-button
                  class="button-theme show-table-maxscreen"
                >Edit</v-button>
                <v-button
                  disabled
                  class="show-table-minscreen"
                  :class="getOptionName('buttonClassOption',item.policy_type)">{{getOptionName('policyTypeOption',item.policy_type)}}</v-button>

              </div>
            </div>
            <div class="table">
              <div></div>
              <div
                class="tr">
                <span>Policy Number</span>
                <span>{{item.policy_no}}
                </span>
              </div>
              <div
                v-if="detail.product_id==10"
                class="tr">
                <span>Cover Note Number</span>
                <span>{{item.covernote_no}}
                </span>
              </div>
              <div
                class="tr">
                <span>Policy Period</span>
                <span>{{showHKTimeSecond(item.effective_date)}} - {{showHKTimeSecond(item.expiry_date)}}
                </span>
              </div>
              <div v-if="item.policy_type===203">
                <div
                  class="tr">
                  <span>Endorsement Effective date</span>
                  <span>{{showHkDate(item.effective_date)}}
                  </span>
                </div>
                <div
                  class="tr">
                  <span>Endorsement Type</span>
                  <span>{{ endorsementType(item.endorsement_type)}}
                  </span>
                </div>
              </div>
              <div v-if="item.policy_type===204">
                <div
                  class="tr">
                  <span>Cancellation Effective date</span>
                  <span>{{showHkDate(item.cancellation_effective_date)}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="pendingOrders.length>0">
          <h2 class="draft-title">Pending Payment</h2>
          <div
            v-for="(item,index) in pendingOrders"
            :key="'pending_'+index"
            class="background-white box-content"
            :class="item.policy_type==203?'background-gray':''"
            @click="handlerPending(item)">
            <div class="flex flex-justify">
              <div class="flex">

                <span class="margin-right">{{showHKTime(item.created_at)}}</span>
                <v-button
                  disabled
                  class="show-table-maxscreen"
                  :class="getOptionName('buttonClassOption',item.policy_type)">{{getOptionName('policyTypeOption',item.policy_type)}}</v-button>
              </div>
              <div>
                <!--                <v-button-->
                <!--                  v-if="item.is_want_renew"-->
                <!--                  class="button-blue "-->
                <!--                  @click="createRenew(item.order_no,detail.product_id)">Create Renew Quote</v-button>-->
                <v-button
                  class="button-theme show-table-maxscreen"
                >Detail</v-button>
                <v-button
                  disabled
                  class="show-table-minscreen"
                  :class="getOptionName('buttonClassOption',item.policy_type)">{{getOptionName('policyTypeOption',item.policy_type)}}</v-button>

              </div>
            </div>
            <div class="table">
              <div></div>
              <div
                class="tr">
                <span>Policy Number</span>
                <span>{{item.policy_no}}
                </span>
              </div>
              <div
                v-if="detail.product_id==10"
                class="tr">
                <span>Cover Note Number</span>
                <span>{{item.covernote_no}}
                </span>
              </div>
              <div
                class="tr">
                <span>Policy Period</span>
                <span>{{showHKTimeSecond(item.effective_date)}} - {{showHKTimeSecond(item.expiry_date)}}
                </span>
              </div>
              <div v-if="item.policy_type===203">
                <div
                  class="tr">
                  <span>Endorsement Effective date</span>
                  <span>{{showHkDate(item.effective_date)}}
                  </span>
                </div>
                <div
                  class="tr">
                  <span>Endorsement Type</span>
                  <span>{{ endorsementType(item.endorsement_type)}}
                  </span>
                </div>
              </div>
              <div v-if="item.policy_type===204">
                <div
                  class="tr">
                  <span>Cancellation Effective date</span>
                  <span>{{showHkDate(item.cancellation_effective_date)}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="paidOrders.length>0">
          <h2 class="draft-title">Policy Record</h2>
          <div
            v-for="(item,index) in paidOrders"
            :key="index"
            class="background-white box-content"
          >
            <div class="flex flex-justify flex-wrap">
              <div
                class="flex"
                @click="premiunInfo(item.order_no)">
                <span class="margin-right">{{showHKTime(item.created_at)}}</span>
                <div>
                  <v-button
                    disabled
                    class="show-table-maxscreen"
                    :class="getOptionName('buttonClassOption',item.policy_type)">{{getOptionName('policyTypeOption',item.policy_type)}}</v-button>
                </div>
              </div>
              <div class="flex flex-wrap">
                <v-button
                  v-if="detail.is_want_renew&&index==0"
                  class="button-blue z-index"
                  @click="createRenew(item.order_no,detail.product_id)">Create Renew Quote</v-button>
                <v-button
                  class="button-theme show-table-maxscreen"
                  @click="premiunInfo(item.order_no)"
                >Detail</v-button>
              </div>
              <div
                class="show-table-minscreen"
                @click="premiunInfo(item.order_no)">
                <v-button
                  disabled
                  class=""
                  :class="getOptionName('buttonClassOption',item.policy_type)">{{getOptionName('policyTypeOption',item.policy_type)}}</v-button>

              </div>
            </div>
            <div
              class="table"
              @click="premiunInfo(item.order_no)">
              <div></div>
              <div
                class="tr">
                <span>Policy Number</span>
                <span>{{item.policy_no||'-'}}
                </span>
              </div>
              <div
                v-if="detail.product_id==10"
                class="tr">
                <span>Cover Note Number</span>
                <span>{{item.covernote_no||'-'}}
                </span>
              </div>
              <div
                class="tr">
                <span>Policy Period</span>
                <span>{{showHKTimeSecond(item.effective_date)}} - {{showHKTimeSecond(item.expiry_date)}}
                </span>
              </div>
              <div v-if="item.policy_type===203">
                <div
                  class="tr">
                  <span>Endorsement Effective date</span>
                  <span>{{showHkDate(item.endorsement_effective_date)}}
                  </span>
                </div>
                <div
                  class="tr">
                  <span>Endorsement Type</span>
                  <span>{{ endorsementType(item.endorsement_type)}}
                  </span>
                </div>
              </div>
              <div v-if="item.policy_type===204">
                <div
                  class="tr">
                  <span>Cancellation Effective date</span>
                  <span>{{showHkDate(item.cancellation_effective_date)}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'

import {getPolicyDetail, getPolicyList} from '@/api/policy'
import {mapActions} from 'vuex'
import {getOrderDetail} from '@api/order'
export default {
  name: 'policyInfo',
  mixins: [MixinOptions],
  data(){
    return{
      detail:{}
    }
  },
  computed:{
    showOtherInsurer(){
      let arr =  [2,3,4,5]
      return !arr.includes(this.detail.company_id)
    },
    policyId(){
      return this.$route.query.policy_id
    },
    paidOrders(){
      return this.detail.paid_orders||[]
    },
    pendingOrders(){
      return this.detail.pending_payment_orders||[]
    },
    draftOrder(){
      return this.detail.draft_orders||[]
    },
    isDraft(){
      return this.$route.query.status===1
    },
    endorsementOptionType()
    {
      let {product_id} = this.detail||{}
      let typesArr = this.globalOptions.endorsement_type
      let arr = []
      typesArr.filter(item=>{
        if(item.product_id == product_id)
          arr = item.items
      })
      return arr.length<=0?[]:arr
    }
  },
  created() {
    this.network().getPolicyDetail()
  },
  methods:{
    ...mapActions('quotation', ['setQuoteInformationForm','setProductID']),
    endorsementType(data){
      let typeArr =[]
      data.forEach(item=>{
        this.endorsementOptionType.some(type => {
          if (type.id == item) {
            typeArr.push(type.name)
          }
        })
      })
      typeArr = typeArr.toString()
      return typeArr
    },
    statusData(id){
      let status = {
        10:'Pending Payment',
        30:'Inforce ',
        40:'Cancelled',
        50:'Expired'
      }
      return status[id]
    },
    handlerInfoHref(){
      this.$router.push('/quotation/create/info')
    },
    createRenew(order_no,productId){
      this.network().getOrderDetail(order_no,false,productId,true)
    },
    //draft edit 返回motor create quotation
    handlerEdit(order_no,bool,productId){
      this.network().getOrderDetail(order_no,true,productId)
    },
    //支付之后返回premiunInfo 页面详情
    premiunInfo(order_no){
      this.setQuoteInformationForm(null)
      this.$router.push({
        path:'/quotation/premium/info',
        query:{
          order_no:order_no
        }
      })
    },
    handlerPending({policy_type,order_no}){
      let {type} = this.$route.query||{}
      this.$router.push({
        name: 'info',
        query:{
          order_no,
          isEndorsement:policy_type==203,
          isPolicyList:type==2?false:true
        }
      })
    },
    network() {
      return {
        getPolicyDetail: async () => {
          const { data } = await getPolicyDetail({
            policy_id:this.policyId ,
          })
          this.detail = data.data

        },
        getOrderDetail: async (order_no,isDraft,productId,isRenew) => {
          let { data } = await getOrderDetail({order_no:order_no})
          let params ={
            order_no,
            ...data.data,
            is_next:true,
          }
          if(isRenew)
          {
            params.order_status = ''
            params.policy_type = 201
          }
          this.setQuoteInformationForm(params)
          this.setProductID(productId)
          let path = {
            10:'/quotation/create/motor',
            11:'/quotation/create/contractors',
            12:'/quotation/create/travel',
            13:'/quotation/create/home',
            14:'/quotation/create/employee',
            15:'/quotation/create/business',
            16:'/quotation/create/domestic',
            17:'/quotation/create/construction',
            18:'/quotation/create/other',
          }

          this.$router.push({
            path:path[productId],
            query:{
              isDraft:isDraft,
              isRenew,
              order_no
            }
          })
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .policy-created{
    .table{
      margin-top: 10px;
    }
    .background-white{
      padding:15px;
      border-radius: 8px;
      background: #fff;
      margin-bottom: 15px;

    }
    .text-end{
      text-align: end;
    }
    .flex{
      //justify-content: space-between;
      align-items: center;
      .company-logo{
        width: 80px;
        height: 42px;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          display: block;
          width: 100%;
          //height: 42px;

        }
      }

    }
    .draft-title{
      margin-bottom: 15px;
    }
    .margin-right{
      //display: block;
      margin-right: 20px;
    }
    .title-content{
      font-size: 16px;
      font-weight: normal;
    }
    .title-margin-bottom{
      margin-bottom: 40px;
      .icon-little{
        margin: 0 14px;
        font-size: 12px;
      }
    }
    .box-content{
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
    }
    .background-gray {
      background: #EDF2F7 !important;
      ::v-deep .table {
        .tr {
          background: #EDF2F7 !important;
        }
      }
    }
    .retweet{
      margin-left: 5px;
    }
    .flex-wrap{
      flex-wrap: wrap;
      .z-index{
        z-index: 100;
      }
    }
  }
</style>
